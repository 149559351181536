import { Link } from 'react-router-dom';

import './styles/Navbar.css';

const Navbar = () => {
  return (
    <nav>
      <div className='logo'>
        <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
          FCIS 2023 <sup>8th</sup>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
