import './components/styles/styles.css';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { AdBlockDetectedWrapper } from 'adblock-detect-react';
import CoursePage from './components/CoursePage';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import NoMatch from './components/NoMatch';
import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-EKWFGLGKVR';
ReactGA.initialize(TRACKING_ID);

const App = () => {
  return (
    <>
      <Router>
        <Navbar />
        <AdBlockDetectedWrapper>
          <div className='detected'>
            Please consider disabling Ad-Blocker to help us maintain this
            project
          </div>
        </AdBlockDetectedWrapper>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route
            path='/TC'
            exact
            component={() => (
              <CoursePage title='Theory of Computation' abb='TC' />
            )}
          />
          <Route
            path='/CA'
            exact
            component={() => <CoursePage title='Computer Animation' abb='CA' />}
          />
          <Route
            path='/CS'
            exact
            component={() => <CoursePage title='Cyber Security' abb='CS' />}
          />
          <Route
            path='/DC'
            exact
            component={() => (
              <CoursePage title='Distributed Computing' abb='DC' />
            )}
          />
          <Route
            path='/SQA'
            exact
            component={() => (
              <CoursePage title='Software Quality Assurance' abb='SQA' />
            )}
          />
          <Route
            path='/SMA'
            exact
            component={() => (
              <CoursePage title='Social Network Analysis' abb='SMA' />
            )}
          />
          <Route
            path='/GIS'
            exact
            component={() => (
              <CoursePage title='Geographic Information Systems' abb='GIS' />
            )}
          />
          <Route
            path='/DS2'
            exact
            component={() => <CoursePage title='Data Science 2' abb='DS2' />}
          />
          <Route
            path='/CC'
            exact
            component={() => <CoursePage title='Cloud Computing' abb='CC' />}
          />
          <Route
            path='/PC'
            exact
            component={() => (
              <CoursePage title='Pervaisve Computing' abb='PC' />
            )}
          />
          <Route
            path='/RTCS'
            exact
            component={() => (
              <CoursePage
                title='Recent Topics in Computer Systems'
                abb='RTCS'
              />
            )}
          />
          <Route
            path='/HCI'
            exact
            component={() => (
              <CoursePage title='Human Computer Interactions' abb='HCI' />
            )}
          />
          <Route
            path='/CR'
            exact
            component={() => (
              <CoursePage title='Computational Robotics' abb='CR' />
            )}
          />
          <Route
            path='/CNS'
            exact
            component={() => (
              <CoursePage title='Computer & Network Security' abb='CNS' />
            )}
          />
          <Route
            path='/GD'
            exact
            component={() => <CoursePage title='Game Design' abb='GD' />}
          />
          <Route
            path='/NLP'
            exact
            component={() => (
              <CoursePage title='Natural Language Processing' abb='NLP' />
            )}
          />
          <Route path='*' component={NoMatch} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default App;
