import './styles/Home.css';

import Courses from './Courses';
import Issues from './Issues';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const Home = () => {
  useEffect(() => {
    document.title = 'FCIS 2023 - 8th Semester';
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: `FCIS2023 - 8th Semester`,
    });
  }, []);

  return (
    <div className='content'>
      <a href='https://StiqaStore.com' className='banner'>
        <img
          src='https://res.cloudinary.com/omar45/image/upload/v1688329814/Stiqa/fcis-banner.webp'
          alt='Stiqa Store banner'
          target='_blank'
        />
      </a>
      <Courses />
      <Issues />
    </div>
  );
};

export default Home;
