import './styles/CoursePage.css';

import { useEffect, useState } from 'react';

import BeatLoader from 'react-spinners/BeatLoader';
import ReactGA from 'react-ga4';
import WeekSeparator from './WeekSeparator';
import firebase from '../firebase';

// import { useIsMounted } from './isMounted';

const CoursePage = (props) => {
  // const isMounted = useIsMounted();
  const { title, abb, teams } = props;
  const [materials, setMaterials] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const map = new Map();

  const updateMaterials = async () => {
    const db = firebase.firestore();
    const data = await db.collectionGroup('materials' + abb).get();
    window.localStorage.setItem(
      'materials' + abb,
      JSON.stringify(data.docs.map((doc) => doc.data()))
    );
  };

  const updateLocalStorage = async () => {
    var time = Date.now();
    if (
      window.localStorage.getItem('lastfetched' + abb) === null ||
      window.localStorage.getItem('lastfetched' + abb) < time - 180000
    ) {
      updateMaterials();
      window.localStorage.setItem('lastfetched' + abb, time);
    }
  };

  useEffect(() => {
    document.title = `${title} | FCIS 2023 8th`;

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: `CoursePage - ${title}`,
    });

    updateLocalStorage();
    if (window.localStorage.getItem('materials' + abb)) {
      setMaterials(JSON.parse(window.localStorage.getItem('materials' + abb)));
      setIsLoading(false);
      setIsEmpty(materials.length === 0);
    } else {
      updateMaterials().then(() => {
        setMaterials(
          JSON.parse(window.localStorage.getItem('materials' + abb))
        );
        setIsLoading(false);
        setIsEmpty(materials.length === 0);
      });
    }
    //eslint-disable-next-line
  }, []);

  const convertMaterialsToMap = () => {
    for (let i = 0; i < 13; i++) map.set(i, []);
    materials.forEach((material) => {
      map.get(material.week).push(material);
      if (isEmpty) setIsEmpty(false);
    });
  };

  return (
    <div className='content'>
      <h1 className='title'>{title}</h1>
      {teams && (
        <h3
          className='teams'
          onClick={() => {
            navigator.clipboard.writeText(teams);
            alert(`'${teams}' was copied to clipboard`);
          }}>
          Teams Code: {teams}
        </h3>
      )}

      {convertMaterialsToMap()}
      {isLoading ? (
        <div style={{ marginTop: '5rem' }}>
          <BeatLoader
            css={'margin: 0 auto;'}
            sizeUnit={'px'}
            size={20}
            margin={5}
            color={'#0078E3'}
          />
        </div>
      ) : (
        Array.from(map).map((arr) => {
          const week = arr[0];
          const materials = arr[1];
          return materials.length > 0 ? (
            <WeekSeparator
              num={week}
              materials={materials}
              course={abb}
              key={week}
            />
          ) : (
            ''
          );
        })
      )}
      {isEmpty && (
        <div className='empty'> No materials have been added yet </div>
      )}
    </div>
  );
};

export default CoursePage;
