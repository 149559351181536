import Material from './Material';

import './styles/WeekSeparator.css';

const WeekSeparator = ({ num, materials, course }) => {
  // const getLastTenLetters = (text) => {
  //   return text.substr(0,5) + text.substr(text.length - 10);
  // };

  // 0 - Video (fas fa-play-circle)
  // 1 - File PDF
  // 2 - File Word (fas fa-file-alt)
  // 3 - File Powerpoint (fas fa-file-alt)
  // 4 - File Rar (fas fa-file-alt)
  // 5 - Code (fas fa-code)
  // 6 - Book (fas fa-book)
  const Order = [6, 0, 1, 2, 3, 4, 5];

  materials.sort((a, b) => {
    const aOrder = Order.indexOf(a.icon);
    const bOrder = Order.indexOf(b.icon);
    return aOrder - bOrder;
  });

  return (
    <div>
      <div className='week'>
        {num === 0 ? <h1>Pinned</h1> : <h1>Week &nbsp;{num}</h1>}
      </div>
      {materials.length > 0 &&
        materials
          .sort((a, b) => a.icon - b.icon || a.title.localeCompare(b.title))
          .map((material) => (
            <Material
              course={course}
              title={material.title}
              link={material.link}
              icon={material.icon}
              key={material.link}
            />
          ))}
    </div>
  );
};

export default WeekSeparator;
