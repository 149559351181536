import { useEffect, useState } from 'react';

import { FilterContext } from '../FilterContext';
import CourseLables from './CourseLables';
import CourseTitle from './CourseTitle';

const Courses = () => {
  const initial = window.localStorage.getItem('filter');
  const [filter, setFilter] = useState(initial || 'all');

  useEffect(() => {
    window.localStorage.setItem('filter', filter);
  }, [filter]);

  return (
    <FilterContext.Provider value={{ filter, setFilter }}>
      <CourseLables />

      {(filter === 'all' || filter === 'CS' || filter === 'CSys') && (
        <CourseTitle name='Cyber Security' to='CS' cs sys />
      )}
      {(filter === 'all' || filter === 'CS') && (
        <CourseTitle name='Theory of Computation' to='TC' cs />
      )}
      {(filter === 'all' || filter === 'CS') && (
        <CourseTitle name='Computer Animation' to='CA' cs />
      )}
      {(filter === 'all' || filter === 'CS') && (
        <CourseTitle name='Distributed Computing' to='DC' cs />
      )}
      {(filter === 'all' || filter === 'IS' || filter === 'CS') && (
        <CourseTitle name='Software Quality Assurance' to='SQA' wrp cs is />
      )}
      {(filter === 'all' || filter === 'IS') && (
        <CourseTitle name='Social Network Analysis' to='SMA' is />
      )}
      {(filter === 'all' || filter === 'IS') && (
        <CourseTitle name='Geographic Information Systems' to='GIS' is wrp />
      )}
      {(filter === 'all' || filter === 'IS') && (
        <CourseTitle name='Data Science 2' to='DS2' is />
      )}
      {(filter === 'all' || filter === 'IS' || filter === 'CSys') && (
        <CourseTitle name='Cloud Computing' to='CC' is sys />
      )}
      {(filter === 'all' || filter === 'CSys') && (
        <CourseTitle name='Pervasive Computing' to='PC' sys />
      )}
      {(filter === 'all' || filter === 'CSys') && (
        <CourseTitle name='RT in Computer Systems' to='RTCS' sys />
      )}
      {(filter === 'all' || filter === 'SC' || filter === 'CSys') && (
        <CourseTitle name='Human-Computer Interactions' to='HCI' sc sys wrp />
      )}
      {(filter === 'all' || filter === 'SC') && (
        <CourseTitle name='Computational Robotics' to='CR' sc />
      )}
      {(filter === 'all' || filter === 'SC') && (
        <CourseTitle name='Computer & Network Security' to='CNS' sc wrp />
      )}
      {(filter === 'all' || filter === 'SC') && (
        <CourseTitle name='Natural Language Processing' to='NLP' sc wrp />
      )}
      {(filter === 'all' || filter === 'SC') && (
        <CourseTitle name='Game Design' to='GD' sc />
      )}
    </FilterContext.Provider>
  );
};

export default Courses;
