import firebase from 'firebase/app';

import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyDVlnjUP_Kk1n70XOMaJlCsWZTqJv05dFY',
  authDomain: 'fcis-last.firebaseapp.com',
  projectId: 'fcis-last',
  storageBucket: 'fcis-last.appspot.com',
  messagingSenderId: '410848028793',
  appId: '1:410848028793:web:949bea7a0d543ccd724ca8',
  measurementId: 'G-0GMHWNPGXV',
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;
